import { createStore } from 'vuex'
import { getDataList } from '@/api/shop'

const store = createStore({
    state () {
        return {
            industryList: [], // 行业分类列表
            otherList: [], // 其他分类列表
        }
    },
    mutations: {
        setIndustryList(state, list) {
            state.industryList = [...(list || [])]
        },
        setOtherList(state, list) {
            state.otherList = [...(list || [])]
        }
    },
    actions: {
        async getIndustryList({ commit }) {
            await getDataList({
                code: 'ari_industry'
            }).then((res) => {
                commit('setIndustryList', res.data)
            }).catch((err) => {
                console.log('err', err)
            })
        },
        async getOtherList({ commit }) {
            await getDataList({
                code: 'ariOther'
            }).then((res) => {
                commit('setOtherList', res.data)
            }).catch((err) => {
                console.log('err', err)
            })
        }
    }
})

export default store
import http from '@/services/http'

// 获取某个字典类型下的字典值
export function getDataList(params) {
    return http.get('/api/GetDataList', params)
}

// 获取算法商城下拉列表
export function getArithmeticListForGet(params) {
    return http.get('/api/ArithmeticShop/GetArithmeticList', params)
}

// 获取算法列表
export function getArithmeticListForPost(data) {
    return http.post('/api/ArithmeticShop/GetArithmeticList', data)
}

// 获取算法详情页信息
export function getDetails(params) {
    return http.get('/api/ArithmeticShop/GetDetails', params)
}

// 获取热门算法接口
export function getHotArithmeticList(params) {
    return http.get('/api/ArithmeticShop/GetHotArithmeticList', params)
}
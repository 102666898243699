import "core-js/modules/es.array.push.js";
import { ref, reactive, onMounted, onUnmounted } from 'vue';
// import DropDownMenu from './DropDownMenu.vue'
import DropDownMenuForProduct from './DropDownMenuForProduct.vue';
import DropDownMenuForMall from './DropDownMenuForMall.vue';
import DropDownMenuForSolution from './DropDownMenuForSolution.vue';
import DropDownMenuForMedia from './DropDownMenuForMedia.vue';
import DropDownMenuForAI from './DropDownMenuForAI.vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'MyHeader',
  components: {
    // DropDownMenu,
    DropDownMenuForProduct,
    DropDownMenuForMall,
    DropDownMenuForSolution,
    DropDownMenuForMedia,
    DropDownMenuForAI
  },
  setup() {
    const list = reactive(['产品与服务', '算法商城', '解决方案', 'AI大模型', '多媒体社区', '关于我们', '联系我们']);
    let active = ref(-1);
    let activeHover = ref(-1);
    const router = useRouter();
    const route = useRoute();
    function changeInfo(item, index) {
      if (item === '产品与服务') {
        active.value = index;
        // 点击进入别的页面，下拉框消失
        if (route.name !== 'ProductService') {
          activeHover.value = -1;
        }
        router.push({
          name: 'ProductService'
        });
      } else if (item === '算法商城') {
        active.value = index;
        // 点击进入别的页面，下拉框消失
        if (route.name !== 'AlgorithmMall') {
          activeHover.value = -1;
        }
        router.push({
          name: 'AlgorithmMall'
        });
      } else if (item === '解决方案') {
        active.value = index;
        // 点击进入别的页面，下拉框消失
        if (route.name !== 'TotalSolution') {
          activeHover.value = -1;
        }
        router.push({
          name: 'TotalSolution'
        });
      } else if (item === 'AI大模型') {
        // router.push({
        //     name: 'AIModel'
        // })
        // 它们都是下拉框，只有点击到下拉框中的内容，才会进入到具体的页面
      } else if (item === '多媒体社区') {
        // router.push({
        //     name: 'MediaCommunity'
        // })
        // 它们都是下拉框，只有点击到下拉框中的内容，才会进入到具体的页面
      } else if (item === '关于我们') {
        active.value = index;
        router.push({
          name: 'AboutUs',
          query: {
            part: 'intro'
          }
        });
      } else if (item === '联系我们') {
        active.value = index;
        router.push({
          name: 'ContactUs'
          // 方便描点
          // query: {
          //     part: 'contact-us-form'
          // }
        });
      }
    }

    function changeEnter(index) {
      activeHover.value = index;
    }
    function changeLeave() {
      activeHover.value = -1;
    }
    const data = reactive({
      oldScrollTop: 0,
      hasReachedTop: true
    });
    const scrolling = () => {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - data.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      data.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        console.log("header 滚动条向上滚动了！");
      } else {
        console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        console.log("header 到了最顶部");
        data.hasReachedTop = true;
      } else {
        data.hasReachedTop = false;
      }
    };
    onMounted(async () => {
      window.addEventListener("scroll", scrolling);
      // 等待路由器完成导航
      await router.isReady();
      // 页面刷新，给active赋值，保证头部正常展示
      // setTimeout千万不能去掉，虽然我也不知道为啥，但是去了会有bug...无奈ing...
      // setTimeout(() => {
      if (route.name === 'HomePage') {
        active.value = -1;
      } else if (route.name === 'ProductService') {
        active.value = 0;
      } else if (route.name === 'AlgorithmMall') {
        active.value = 1;
      } else if (route.name === 'TotalSolution') {
        active.value = 2;
      } else if (route.name === 'AIModel') {
        active.value = 3;
      } else if (route.name === 'MediaCommunity') {
        active.value = 4;
      } else if (route.name === 'AboutUs') {
        active.value = 5;
      } else if (route.name === 'ContactUs') {
        active.value = 6;
      } else if (route.name === 'ProductServiceDetail') {
        active.value = 0;
      } else if (route.name === 'AlgorithmMallDetail') {
        active.value = 1;
      } else if (route.name === 'TotalSolutionDetail') {
        active.value = 2;
      } else if (route.name === 'DataDownload' || route.name === 'DataDownloadDetail' || route.name === 'EventExpo' || route.name === 'VisionCourse') {
        active.value = 4;
      }
      // }, 0)
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scrolling);
    });
    function goHome() {
      active.value = -1;
      router.push({
        name: 'HomePage'
      });
    }
    // 接收产品与服务下拉框子组件传过来的事件
    function tellParentClickForProduct(index) {
      active.value = 0;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'ProductServiceDetail',
        query: {
          idx: index
        }
      });
    }
    function tellParentAllForProduct() {
      active.value = 0;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'ProductService'
      });
    }

    // 接收算法商城下拉框子组件传过来的事件
    function tellParentClickForMall(id) {
      active.value = 1;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'AlgorithmMallDetail',
        query: {
          id
        }
      });
    }
    function tellParentAllForMall() {
      active.value = 1;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'AlgorithmMall'
      });
    }

    // 接收解决方案下拉框子组件传过来的事件
    function tellParentClickForSolution(index) {
      active.value = 2;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'TotalSolutionDetail',
        query: {
          idx: index
        }
      });
    }
    function tellParentAllForSolution() {
      active.value = 2;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      router.push({
        name: 'TotalSolution'
      });
    }

    // 接收多媒体社区下拉框子组件传过来的事件
    function tellParentClickForMedia(item) {
      console.log('item', item);
      active.value = 4;
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      if (item === '资料下载') {
        router.push({
          name: 'DataDownload'
        });
      }
      if (item === '活动展会') {
        router.push({
          name: 'EventExpo'
        });
      }
      if (item === '可视化教程') {
        router.push({
          name: 'VisionCourse'
        });
      }
    }

    // 接收AI大模型下拉框子组件传过来的事件
    function tellParentClickForAI(item) {
      console.log('item', item);
      // active.value = 3
      // 点击进入别的页面，下拉框消失
      activeHover.value = -1;
      if (item === '数据管理平台') {
        window.open('http://59.110.49.119:9050/');
      }
      if (item === '模型训练平台') {
        window.open('http://47.94.104.178:9003/');
      }
      if (item === '远志AI标注平台') {
        window.open('http://47.94.104.178:8089/');
      }
      if (item === '万能小智') {
        window.open('https://llm.huashuyz.com/home/');
      }
    }

    // 点击申请试用，跳转到联系我们
    function goToContactUs() {
      active.value = 6;
      router.push({
        name: 'ContactUs'
        // 方便描点
        // query: {
        //     part: 'contact-us-form'
        // }
      });
    }

    return {
      list,
      active,
      activeHover,
      changeInfo,
      changeEnter,
      changeLeave,
      data,
      goHome,
      tellParentClickForProduct,
      tellParentAllForProduct,
      tellParentClickForMall,
      tellParentAllForMall,
      tellParentClickForSolution,
      tellParentAllForSolution,
      tellParentClickForMedia,
      goToContactUs,
      tellParentClickForAI
    };
  }
};
// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
const HomePage = () => import('@/views/HomePage')
const ProductService = () => import('@/views/ProductService')
const AlgorithmMall = () => import('@/views/AlgorithmMall')
const TotalSolution = () => import('@/views/TotalSolution')
const AIModel = () => import('@/views/AIModel')
const MediaCommunity = () => import('@/views/MediaCommunity')
const AboutUs = () => import('@/views/AboutUs')
const ContactUs = () => import('@/views/ContactUs')

const ProductServiceDetail = () => import('@/views/ProductServiceDetail')
const AlgorithmMallDetail = () => import('@/views/AlgorithmMallDetail')
const TotalSolutionDetail = () => import('@/views/TotalSolutionDetail')

// const DataDownload = () => import('@/views/DataDownload')
// const DataDownloadDetail = () => import('@/views/DataDownloadDetail')
// const EventExpo = () => import('@/views/EventExpo')
const VisionCourse = () => import('@/views/VisionCourse')

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/product',
        name: 'ProductService',
        component: ProductService
    },
    {
        path: '/mall',
        name: 'AlgorithmMall',
        component: AlgorithmMall
    },
    {
        path: '/solution',
        name: 'TotalSolution',
        component: TotalSolution
    },
    {
        path: '/ai',
        name: 'AIModel',
        component: AIModel
    },
    {
        path: '/media',
        name: 'MediaCommunity',
        component: MediaCommunity
    },
    {
        path: '/about',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/product-detail',
        name: 'ProductServiceDetail',
        component: ProductServiceDetail
    },
    {
        path: '/mall-detail',
        name: 'AlgorithmMallDetail',
        component: AlgorithmMallDetail
    },
    {
        path: '/solution-detail',
        name: 'TotalSolutionDetail',
        component: TotalSolutionDetail
    },
    // {
    //     path: '/data-download',
    //     name: 'DataDownload',
    //     component: DataDownload
    // },
    // {
    //     path: '/data-download-detail',
    //     name: 'DataDownloadDetail',
    //     component: DataDownloadDetail
    // },
    // {
    //     path: '/event-expo',
    //     name: 'EventExpo',
    //     component: EventExpo
    // },
    {
        path: '/vision-course',
        name: 'VisionCourse',
        component: VisionCourse
    }
]

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from) => {
    console.log('to', to, 'from', from)
    // 切换页面，回到顶部
    window.scrollTo(0, 0)
})

export default router
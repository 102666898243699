import { reactive } from 'vue';
export default {
  name: 'DropDownMenuForAI',
  setup(props, context) {
    const list = reactive(['远志AI标注平台', '模型训练平台', '数据管理平台', '万能小智']);
    // 告诉父组件用户点击了多媒体社区的下拉框
    function tellParentClick(item) {
      context.emit('tellParentClick', item);
    }
    return {
      list,
      tellParentClick
    };
  }
};
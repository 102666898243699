import axios from 'axios';
import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

service.interceptors.request.use((config) => {
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  if(/^(4|5)\d{2}$/.test(response.data.code)) {
    //抛出错误
    ElMessage.error(response.data.message)
    return Promise.reject(response.data)
  }
  return response.data
}, (error) => {
  let msg = error && error.message ? error.message : error
  ElMessage.error(msg)
  return Promise.reject(error)
})

export default service
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61f7c21c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "drop-down-menu-for-ai flex-column flex-vertical-center"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (item, index) => {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "nowrap font-16 c-14161A drop-down-menu-for-media-item",
      onClick: $event => $setup.tellParentClick(item)
    }, _toDisplayString(item), 9, _hoisted_2);
  }), 128))]);
}
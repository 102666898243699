import { reactive, onMounted, toRefs, computed } from 'vue';
import { getArithmeticListForGet } from '@/api/shop';
import { useStore } from 'vuex';
export default {
  name: 'DropDownMenuForMall',
  setup(props, context) {
    const store = useStore();
    let data = reactive({
      industryList: computed(() => store.state.industryList),
      // 行业算法分类
      name: '',
      id: '',
      // name: computed(() => {
      //     return (data.industryList.length && data.industryList[0].name) ? data.industryList[0].name : ''
      // }), // 选中的行业算法名字
      // id: computed(() => {
      //     return (data.industryList.length && data.industryList[0].id) ? data.industryList[0].id : ''
      // }), // 选中的行业算法id
      detailList: [],
      // 选中的行业算法 列表
      // industryloading: false,
      detailloading: false
    });
    // 告诉父组件用户点击了下拉框中的热门推荐与产品服务
    function tellParentClick(id) {
      context.emit('tellParentClick', id);
    }
    function tellParentAll() {
      context.emit('tellParentAll');
    }
    onMounted(() => {
      // 获取行业算法分类
      // getIndustryList()
      data.name = data.industryList.length && data.industryList[0].name ? data.industryList[0].name : '';
      data.id = data.industryList.length && data.industryList[0].id ? data.industryList[0].id : '';
      // 选中的行业算法 列表
      getDetailList();
    });
    function changeInfo(item) {
      data.id = item.id;
      data.name = item.name;
      // 选中的行业算法 列表
      getDetailList();
    }

    // 选中的行业算法 列表
    function getDetailList() {
      if (!data.id) {
        data.detailloading = false;
        return;
      }
      data.detailloading = true;
      getArithmeticListForGet({
        id: data.id
      }).then(res => {
        data.detailList = res.data;
        data.detailloading = false;
      }).catch(err => {
        console.log('err', err);
        data.detailloading = false;
      });
    }
    return {
      ...toRefs(data),
      tellParentClick,
      tellParentAll,
      changeInfo
    };
  }
};
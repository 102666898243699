import { reactive, provide, toRefs, nextTick, onMounted } from 'vue';
import MyHeader from './components/MyHeader.vue';
// import MyFooter from './components/MyFooter.vue'

import { useStore } from 'vuex';
export default {
  name: 'App',
  components: {
    MyHeader
    // MyFooter
  },

  setup() {
    let data = reactive({
      isAlive: true // 控制header组件展示隐藏
    });
    // 刷新header组件
    function reload() {
      data.isAlive = false;
      nextTick(() => {
        data.isAlive = true;
      });
    }
    // 将刷新header组件的方法向后代组件提供
    provide('refresh', reload);
    const store = useStore();
    const getIndustryList = () => store.dispatch('getIndustryList');
    const getOtherList = () => store.dispatch('getOtherList');
    onMounted(() => {
      getIndustryList(); // 请求算法商城的行业列表
      getOtherList(); // 请求算法商城的其他列表
    });

    return {
      ...toRefs(data)
    };
  }
};
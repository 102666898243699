import { reactive } from 'vue';
export default {
  name: 'DropDownMenuForSolution',
  setup(props, context) {
    const leftList = reactive(['智慧交通', '智慧矿山AI安全监管', '智慧城管']);
    const rightList = reactive([{
      title: '智慧交通'
    }, {
      title: '智慧矿山AI安全监管'
    }, {
      title: '智慧城管'
    }, {
      title: '明厨亮灶'
    }, {
      title: '智慧园区'
    }, {
      title: '智慧工业AI安全生产'
    }
    // {
    //     title: '智慧工业解决方案',
    // },
    // {
    //     title: '无人机ai智能巡检解决方案',
    // },
    // {
    //     title: '智慧矿山解决方案',
    // },
    // {
    //     title: '智慧安防解决方案',
    // },
    // {
    //     title: '智慧水务解决方案'
    // },
    // {
    //     title: '新能源充电站AI解决方案'
    // },
    // {
    //     title: '智慧电网解决方案'
    // },
    // {
    //     title: '智慧农业解决方案'
    // },
    // {
    //     title: '智慧供热解决方案'
    // },
    // {
    //     title: '智慧加油站解决方案'
    // },
    // {
    //     title: '工业质检解决方案'
    // },
    // {
    //     title: '自动驾驶解决方案'
    // },
    // {
    //     title: '智慧民航解决方案'
    // },
    // {
    //     title: '金融产品智能解决方案'
    // },
    ]);
    // 告诉父组件用户点击了下拉框中的热门推荐与产品服务
    function tellParentClick(index) {
      context.emit('tellParentClick', index);
    }
    function tellParentAll() {
      context.emit('tellParentAll');
    }
    return {
      leftList,
      rightList,
      tellParentClick,
      tellParentAll
    };
  }
};
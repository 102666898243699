import { reactive } from 'vue';
export default {
  name: 'DropDownMenuForMedia',
  setup(props, context) {
    // const list = reactive(['资料下载', '可视化教程', '干货分享', '行业快讯', '活动展会'])
    const list = reactive(['可视化教程']);
    // 告诉父组件用户点击了多媒体社区的下拉框
    function tellParentClick(item) {
      context.emit('tellParentClick', item);
    }
    return {
      list,
      tellParentClick
    };
  }
};
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/reset.css'
import './assets/css/normalize.css'

import 'animate.css';

createApp(App).use(router).use(store).mount('#app')

import { reactive } from 'vue';
export default {
  name: 'DropDownMenuForProduct',
  setup(props, context) {
    const leftList = reactive(['智能标注', '训练平台', '数据管理平台', '边缘控制台']);
    const rightList = reactive([{
      title: '智能标注',
      content: 'AI算法领域的全类型标注平台'
    }, {
      title: '训练平台',
      content: '零代码自动化AI训练平台'
    }, {
      title: '数据管理平台',
      content: '专为企业打造的AI数据集管理存储工具'
    }, {
      title: '边缘控制台',
      content: '针对边缘设备本地化管理平台'
    }
    // {
    //     title: 'AI大模型',
    //     content: '人工智能AI问答系统'
    // },
    // {
    //     title: '国产替代',
    //     content: '基于国产平台应用软件系统搭建'
    // },
    // {
    //     title: 'Mind Box · AI智能盒子',
    //     content: 'AI边缘算法智能盒子'
    // },
    // {
    //     title: '钢铁缺陷视觉检测系统',
    //     content: '自主研发的AI视觉检测系统'
    // },
    // {
    //     title: '远志人脸识别系统',
    //     content: '人脸识别AI技术系统'
    // },
    ]);
    // 告诉父组件用户点击了下拉框中的热门推荐与产品服务
    function tellParentClick(index) {
      context.emit('tellParentClick', index);
    }
    function tellParentAll() {
      context.emit('tellParentAll');
    }
    return {
      leftList,
      rightList,
      tellParentClick,
      tellParentAll
    };
  }
};